import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AcquisitionRepository, AcquisitionAPI, EngagementRepository, InsightAPI, ProcessingAPI, SpotifyAPI, UserRepository, InsightDataAPI } from '@warnerconnect/library';

import { TablePagination, Box, InputLabel, Tooltip, InputAdornment, Select, TextField, MenuItem, Button, Typography, AccordionSummary, AccordionDetails, Accordion, CircularProgress, Link as MLink, Chip, Slider, Alert, Stepper, Step, StepButton, StepLabel, Divider, Menu } from '@mui/material';

import { useAuthDispatch, logout, useAuthState, changeFanTeam } from '../../../Context';

import {
    CalendarMonth as CalendarMonthIcon,
    AddBox as AddBoxIcon,
    ArrowBack as ArrowBackIcon,
    Info as InfoIcon,
    ExpandMore as ExpandMoreIcon,
    RemoveCircle as RemoveCircleIcon,
    PersonSearch as PersonSearchIcon,
    Send as SendIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material';

import { formReducer } from '../../../Context/formReducer';

import { campaignTypes } from '../../../Config/campaigns';

import { useSnackbar } from "notistack";

import dateFormat, { masks } from "dateformat";

import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import FileUpload from '../../Elements/Forms/Fields/FileUpload';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import Fieldset from '../../Elements/Forms/Fieldset';
import ContentBox from '../../Elements/ContentBox';
import ModalDialogue from '../../Elements/ModalDialogue';
import CampaignTypeSelector from '../../Elements/CampaignTypeSelector';
import PageContainer from '../../Elements/PageContainer';
import CountrySelector from '../../Elements/Forms/Fields/CountrySelector';
import DSPSearch from '../../Elements/Forms/Fields/DSPSearch';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import ReleaseList from '../../Elements/ReleaseList';
import ReleaseListSingle from '../../Elements/ReleaseListSingle';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import ImageUpload from '../../Elements/Forms/Fields/ImageUpload';
import EntrantSearch from '../../Elements/Forms/Fields/EntrantSearch';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {LocalizationProvider, TimePicker, DateTimePicker, DesktopDatePicker, MobileDatePicker} from '@mui/x-date-pickers';
import {LoadingButton} from '@mui/lab';
import Search from '../../Elements/Forms/Fields/Search';
import { addDays, differenceInDays, subDays } from 'date-fns';

export default function InsightsAudienceBuilderIndex() {
    useTitle('Insights');

    const steps = ['Create Audience', 'Create Email', 'Schedule Send', 'Confirm'];
    const dateRangeThreshold = 28; // Number of days that date ranges can span, at most

    const userDetails = useAuthState();

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [exportEmailsAccess, setExportEmailsAccess] = React.useState(false);
    
    const isStepOptional = (step) => {
      return false;
    };
  
    const isStepSkipped = (step) => {
        return skipped.has(step);
      };
  
      const isStepSkippable = (step) => {
        return skipped.has(step);
      };

      const isStepCompleted = (step) => {
        if( step === 0 )
        {
            return !audienceDataLoading && (audienceDataTotal !== null);
        }
        else if( step === 1 )
        {
            if( emailFormData.type && emailFormData.language )
            {
                var valid = true;

                let emailTemplate = emailTemplateData.getItems().find(item => item.getId() === emailFormData.type);
                let translation = emailTemplate.getTranslations().find(item => item.getLanguage().getId() === emailFormData.language);

                if(  translation && translation.getText() )
                {
                    translation.getText().forEach(fieldSingle => {
                        if( !emailFormData[fieldSingle.name] )
                        {
                            valid = false;
                        }
                    });
                }
                else
                {
                    valid = false;
                }

                return valid;
            }
            else
            {
                return false;
            }

        }
        else if( step === 2 )
        {
            return emailFormData.sendDateTime;
        }
        else if( step === 3 )
        {
            return audienceSchedule || ( audienceScheduleSubmitting === false );
        }
      }
      
    const handleNext = () => {
        if( ( activeStep + 1 ) === ( steps.length + 1 ) )
        {
            handleReset();
        }
        else if( ( activeStep + 1 ) === steps.length )
        {
            setAudienceScheduleSubmitting(true);

            let newAudienceData = {
                user: userDetails.user.id,
                emailData: emailFormData,
                queryData: audience.filters,
                emailTemplate: emailFormData.type,
                language: emailFormData.language,
                sendDateTime: emailFormData.sendDateTime
            };

            if( userDetails.team )
            {
                newAudienceData.team = userDetails.team;
            }

            engagementRepository
                .createAudience(newAudienceData)
                    .then(async (newAudience) => {
                        setAudienceSchedule(newAudience);

                        let total = audienceDataTotal;
                        let limit = 50000;
                        let offset = 0;
        
                        do
                        {
                            audienceData.limit = limit;
                            audienceData.offset = offset;
                            let spotifyAudience = await insightDataAPI.getAudience(audienceData);

                            let entrantIds = Array.from(spotifyAudience, item => item.ENTRANTID);
        
                            await engagementRepository.addAudienceEntrants(newAudience.getId(), entrantIds);

                            offset = offset + limit;
        
                        }while( offset < total );
        
                        setAudienceScheduleSubmitting(false);

                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    });
        }
        else
        {
            let newSkipped = skipped;

            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }

    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setAudienceData(null);
      setAudienceSchedule(null);
      setEmailFormData({type: 'reset'});
      setAudience({filters: [{expanded: true}]});
      setAudienceDataTotal(null);
    };

    const { enqueueSnackbar } = useSnackbar();

    const [audienceScheduleSubmitting, setAudienceScheduleSubmitting] = useState(false);
    const [audienceSchedule, setAudienceSchedule] = useState();

    const [emailTemplateData, setEmailTemplateData] = useState();
    const [emailTemplateDataLoading, setEmailTemplateDataLoading] = useState(true);

    const [userAudienceCountries, setUserAudienceCountries] = useState();
    const [userAudienceCountriesLoading, setUserAudienceCountriesLoading] = useState(true);

    const [countryData, setCountryData] = useState();
    const [countryDataLoading, setCountryDataLoading] = useState(true);

    const [audienceData, setAudienceData] = useState({});
    const [audienceDataTotal, setAudienceDataTotal] = useState(null);
    const [audienceDataLoading, setAudienceDataLoading] = useState(false);

    const [exportData, setExportData] = useState();
    const [exportModal, setExportModal] = useState(false);

    let query = useQuery();
    const history = useHistory();

    const [audience, setAudience] = useState({filters: [{expanded: true}]});
    const [emailFormData, setEmailFormData] = useReducer(formReducer, {});
    const [emailTestLoading, setEmailTestLoading] = useState(false);
    
    const acquisitionAPI = new AcquisitionAPI();
    const userRepository = new UserRepository();
    const acquisitionRepository = new AcquisitionRepository();
    const processingAPI = new ProcessingAPI();
    const engagementRepository = new EngagementRepository();
    const spotifyAPI = new SpotifyAPI();
    const insightAPI = new InsightAPI();
    const insightDataAPI = new InsightDataAPI();
    insightAPI.setMode('bigquery');

    acquisitionAPI.setBearerToken(userDetails.accessToken);
    userRepository.setBearerToken(userDetails.accessToken);
    acquisitionRepository.setBearerToken(userDetails.accessToken);
    processingAPI.setBearerToken(userDetails.accessToken);
    engagementRepository.setBearerToken(userDetails.accessToken);
    insightAPI.setBearerToken(userDetails.accessToken);
    insightDataAPI.setBearerToken(userDetails.accessToken);

    const [anchorExportMenu, setAnchorExportMenu] = useState(null);
  
    const openExportMenu = Boolean(anchorExportMenu);

    const toggleExportMenu = (event) => {
        setAnchorExportMenu(anchorExportMenu ? null : event.currentTarget);
    };
    
    const toggleExportModal = event => {
        setExportModal(!exportModal);
    }
      
    useEffect(() => {
        userRepository.getUserPermission(userDetails.user.id, 'audienceBuilderExportAccess')
            .then( userPermission => {
                if( userPermission.getValue() )
                {
                  setExportEmailsAccess(userPermission.getValue());
                }
            })
            // User doesn't have this permission
            .catch(error => {
    
            });

    }, []);
    
    useEffect(() => {
        if( emailFormData.type && emailFormData.language && ( emailFormData.artist || emailFormData.playlist || emailFormData.campaign || ['Custom'].includes(emailTemplateData.getItems().find(item => item.getId() === emailFormData.type).getName()) ) )
        {
            let emailTemplate = emailTemplateData.getItems().find(item => item.getId() === emailFormData.type);
            let translation = emailTemplate.getTranslations().find(item => item.getLanguage().getId() === emailFormData.language);

            if( emailFormData.artist )
            {
                setEmailFormDataSingle('buttonLink', emailFormData.artist.uri);
            }
            else if( emailFormData.playlist )
            {
                setEmailFormDataSingle('buttonLink', emailFormData.playlist.uri);
            }
            if( translation && translation.getText() )
            {
                translation.getText().forEach(textItem => {
                    let modifiedValue = textItem.value;

                    if( emailFormData.artist )
                    {
                        modifiedValue = modifiedValue.replaceAll('{P1}', emailFormData.artist.label);
                    }
                    else if( emailFormData.playlist )
                    {
                        modifiedValue = modifiedValue.replaceAll('{P1}', emailFormData.playlist.label);
                    } else if( emailFormData.campaign )
                    {
                        modifiedValue = modifiedValue.replaceAll('{P1}', emailFormData.campaign.artist);
                        modifiedValue = modifiedValue.replaceAll('{P2}', emailFormData.campaign.context);
                    }

                    if( ['title', 'buttonText'].includes(textItem.name) )
                    {
                        modifiedValue = modifiedValue.toUpperCase();
                    }

                    setEmailFormDataSingle(textItem.name, modifiedValue);
                });
            }
        }
    }, [emailFormData.artist, emailFormData.playlist, emailFormData.language, emailFormData.campaign]);

    useEffect(() => {
        acquisitionRepository.getCountries({limit: 200})
            .then( countries => {
                var countryData = [];

                countries.getItems().forEach(country => {
                    countryData.push(country);
                });

                setCountryData(countryData);
                setCountryDataLoading(false);
            });

        engagementRepository.getEmailTemplates({limit: 200, include: ['translations', 'translation.language']})
            .then( emailTemplate => {
                setEmailTemplateData(emailTemplate);
                setEmailTemplateDataLoading(false);
            });

    }, []);

    useEffect(() => {
        let newAudience = {...audienceData}
        newAudience.filters = newAudience.filters || [];

        if( countryData )
        {

            if( query.get('audience') )
            {
                newAudience = {filters: []};
                
                let audience = atob(query.get('audience'));
                let newAudienceParsed = JSON.parse(audience);
                
                newAudienceParsed.filters.filter(audienceParsedSingle => audienceParsedSingle.context === 'countries').forEach(audienceCountrySingle => {
                    audienceCountrySingle.value = Array.from(audienceCountrySingle.value, audienceCountrySingleValue => {
                        return countryData.find(countryDataSingle => audienceCountrySingleValue.id === countryDataSingle.getId());
                    });
                });

                newAudience = newAudienceParsed;

                setAudience(newAudience);
            }

            userRepository.getUserPermission(userDetails.user.id, 'audienceCountries')
                .then( userPermission => {
                    let audienceCountries;
                    if( userPermission.getValue() )
                    {
                        audienceCountries = userPermission.getValue() ? JSON.parse(userPermission.getValue()) : null;
                        audienceCountries = Array.isArray(audienceCountries) && ( audienceCountries.length > 0 ) ? audienceCountries : null;
                    }

                    setUserAudienceCountries(audienceCountries);
                    setUserAudienceCountriesLoading(false);
                })
                // User doesn't have this permission
                .catch(error => {
                    setUserAudienceCountriesLoading(false);
                });

        }
    }, [countryData]);



    const handleTestEmailSend = () => (event) => {
        setEmailTestLoading(true);

        let emailTemplate = emailTemplateData.getItems().find(item => item.getId() === emailFormData.type);

        let emailBatch = [];

        emailFormData.testEmail.forEach(emailSingle => {
            let emailBatchPayLoad = {
                SubjectLine: emailFormData.subject,
                preheader: emailFormData.preHeader,
                ArtistImageURL: emailFormData.image,
                TrackURL: emailFormData.buttonLink,
                Copy1: emailFormData.title,
                Copy2: emailFormData.header,
                Copy4: emailFormData.content,
                Copy5: emailFormData.buttonText,
                email: emailSingle.label,
                _trigger: emailTemplate.getTriggerId(),
                _triggerEXT: emailTemplate.getTriggerExtension()
            };

            if( (emailTemplate.getName() === 'One Click Pre Save') && emailFormData.campaign ) {
                emailBatchPayLoad.TrackURL = 'https://wct.live/e/presave/' + emailSingle.id + '/' + emailFormData.campaign.id;
            } else if( emailTemplate.getName() === 'Follow')  {
                emailBatchPayLoad.TrackURL = 'https://wct.live/e/follow/' + emailSingle.id + '?uris[]=' + emailFormData.buttonLink;
            }

            emailBatch.push(emailBatchPayLoad);
        });

        processingAPI
            .queueCDCBatch(emailBatch)
            .then(cdcResponse => {
                setEmailTestLoading(false);
                enqueueSnackbar(emailBatch.length === 1 ? emailBatch.length+" test email sent" : emailBatch.length+" test emails sent", {
                    variant: 'success'
                });

            });
    }

    const setEmailFormDataSingle = (name, value) => {
        setEmailFormData({ name: name, value: value});
    };

    const handleEmailFormDataChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        if( event.target.name === 'type' )
        {
            setEmailFormData({type: 'reset'});
        }

        setEmailFormDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleExpansionChange = (filterId) => (event) => {
        setExpansionChange(filterId, !audience.filters[filterId].expanded);
    };

    const setExpansionChange = (filterId, expanded) => {
        var newAudience = {...audience};

        newAudience.filters[filterId].expanded = expanded;

        setAudience(newAudience);
    };

    const handleExport = (type) => (event) => {
        setExportData(null);
        setExportModal(true);

        if( exportEmailsAccess === true ){
            acquisitionRepository
                .createExport({
                    user: userDetails.user.id,
                    type: 'default'
                })
                .then(async (exportResponse) => {
                    let total = audienceDataTotal;
                    let limit = 50000;
                    let offset = 0;

                    do
                    {
                        audienceData.limit = limit;
                        audienceData.offset = offset;
                        let spotifyAudience = await insightDataAPI.getAudience(audienceData);
                        
                        let entrantIds = Array.from(spotifyAudience, item => item.ENTRANTID);

                        await acquisitionRepository.addExportEntrants(exportResponse.getId(), entrantIds);

                        offset = offset + limit;
                    }while( offset < total );

                    acquisitionRepository
                        .updateExport(exportResponse.getId(), {
                            imported: true
                        })
                        .then(() => {
                            acquisitionAPI.genereateExport(exportResponse.getId())
                                .then( generatedExportResponse => {
                                    setExportData(generatedExportResponse);
                                });
                        });
                });
        }

    }

    const handleFilterChangeSingle = (filterId, field, value) => {
        var newAudience = {...audience};

        newAudience.filters[filterId][field] = value;

        setAudience(newAudience);
    }

    const handleFilterChange = (filterId) => (event) => {
        const isCheckbox = event.target.type === 'checkbox';

        var newAudience = {...audience};

        if( event.target.name === 'context' )
        {
            newAudience.filters[filterId] = {
                type: newAudience.filters[filterId].type,
                context: event.target.value,
                expanded: true
            };
        }
        else
        {
            newAudience.filters[filterId][event.target.name] = isCheckbox ? event.target.checked : event.target.value;
        }

        setAudience(newAudience);
    }

    const handleFilterDelete = (filterId) => (event) => {
        event.stopPropagation();

        var newAudience = {...audience};

        newAudience.filters.splice(filterId, 1);

        setAudience(newAudience);
    }

    const handleFilterAdd = () => (event) => {
        var newAudience = {...audience};

        newAudience.filters.push({expanded: true});

        setAudience(newAudience);
    }

    const handleFilterSearch = () => async (event) => {
        setAudienceDataLoading(true);

        let filterAudience = {...audience};

        let updatedAudienceBuilder = {
            data: []
        };

        // Check to make sure a country filter isnt already being used
        let existingCountryFilter = filterAudience.filters.find(filterSingle => filterSingle.searchType === 'countries' && filterSingle.queryType === 'include');

        // If not then apply the current users country filters
        if( userAudienceCountries && !existingCountryFilter )
        {
            filterAudience.filters.push({
                searchType: "countries",
                queryType: "include",
                expanded: true,
                items: countryData.filter(countryDataSingle => userAudienceCountries.includes(countryDataSingle.getA2()))
            });
        }

        for(let filterIndex in filterAudience.filters) {
            let filter = filterAudience.filters[filterIndex];
            //if( filter.expanded )
            //{
                //setExpansionChange(filterIndex, false);
            //}

            let itemList = [];
            let value = {
                searchType: filter.context,
                queryType: filter.type,
            };

            if( ['countries'].includes(filter.context) )
            {
                filter.value.forEach(item => itemList.push(item.getA2()));
                
                value.items = itemList;
            }
            else if( ['topArtists', 'topArtistsSimilar'].includes(filter.context) )
            {

                if( ['topArtistsSimilar'].includes(filter.context) )
                {
                    value.context = 'topArtists';

                    await filter.value.reduce(async (memo, item) => {
                        await memo;
                        let similarArtists = await spotifyAPI.getArtistRelatedArtists(item.id);
                        let similarArtistsTop = similarArtists.artists ? similarArtists.artists.slice(0, 5) : null;

                        if( similarArtistsTop )
                        {
                            similarArtistsTop.forEach(similarArtist => itemList.push(similarArtist.id));
                        }
                    }, undefined);
                }
                else
                {
                    filter.value.forEach(item => itemList.push(item.id));
                }
                
                value.topArtistTimeFrame = 'medium';
                value.topArtistType = filter.preference || 'any';
                value.topArtistMinThreshold = filter.valueRange ? filter.valueRange[0] : 0;
                value.topArtistMaxThreshold = filter.valueRange ? filter.valueRange[1] : 50;
                value.items = itemList;
            }
            else
            {
                filter.value.forEach(item => itemList.push(item.id));
                value.items = itemList

                if( filter.dateFrom && filter.dateTo ){
                    const dateFrom = dateFormat(filter.dateFrom, 'yyyy-mm-dd');
                    const dateTo = dateFormat(filter.dateTo, 'yyyy-mm-dd');
                    value.dateFrom = dateFrom;
                    value.dateTo = dateTo;
                }
            }

            updatedAudienceBuilder.data.push(value);
        }

        setAudienceData(updatedAudienceBuilder);

        if( updatedAudienceBuilder.data && updatedAudienceBuilder.data.length > 0 )
        {
            insightDataAPI.getAudienceTotal(updatedAudienceBuilder)
                .then(audienceIncludeTotalResponse => {
                    let audienceIncludeTotal = audienceIncludeTotalResponse.total;

                    setAudienceDataTotal(audienceIncludeTotal);

                    return audienceIncludeTotalResponse;
                })
                .then(() => {
                    setAudienceDataLoading(false);
                });
        }
        else
        {
            setAudienceDataLoading(false);
        }
    }

    const contextOptions = [
        {value: 'topArtists', label: 'Regularly Listen to a Particular Artist'},
        {value: 'topArtistsSimilar', label: 'May be Interested in Listening to a Particular Artist'},
        {value: 'artists', label: 'Have Listened to a Particular Artist Between Certain Dates'},
        {value: 'albums', label: 'Have Listened to a Particular Album Between Certain Dates'},
        {value: 'tracks', label: 'Have Listened to a Particular Track Between Certain Dates'},
        {value: 'playlists', label: 'Have Listened to a Particular Playlist Between Certain Dates'},
        {value: 'countries', label: 'Live in a Particular Country'},
        {value: 'libraryArtists', label: 'Have a Particular Artist in Their Library (Follows This Artist)'}
    ];

    return (
        <>
            <ModalDialogue open={exportModal} size="large" onClose={toggleExportModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Export Data</Typography>      
                    {
                        ( exportEmailsAccess === false ) &&
                            <Alert
                                severity="info"
                                sx={{mt: 2}}
                            >
                                In order to make Warner Connect as secure as possible with it's user data, we've been asked to review how we expose sensitive data.<br /><br />Please get in touch with <MLink href="mailto:warner-connect-support@wmg.com" target="_blank">warner-connect-support@wmg.com</MLink> if you require access to export user data.
                            </Alert>
                    }
                    {
                        ( exportEmailsAccess === true ) && <>
                            {
                                exportData ?
                                    <Alert
                                        severity="success"
                                        sx={{mt: 2}}
                                    >
                                    Your fan data export has been generated. <MLink href={exportData.uri} target="_blank">Click here if your report didn't download automatically</MLink>.
                                    </Alert>
                                :
                                    <Alert
                                        icon={<CircularProgress size={20} />}
                                        severity="info"
                                        sx={{mt: 2}}
                                    >
                                    Please wait while we build your fan data export. For large audiences, this can take several minutes.
                                    </Alert>
                            }
                        </>
                    }

           
                </Box>

                
            </ModalDialogue>

            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" gutterBottom>Audience Builder</Typography>
                    <Typography paragraph>Build an audience based on our fans favourite artists, tracks, albums & playlists they've listened to recently.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton color="secondary" component={Link} to="/insights">View Schedule<CalendarMonthIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>
            <PageContainer>
                <Typography variant="h4" gutterBottom>Create Audience</Typography>


<ContentBox margin={true}>

{
    userAudienceCountriesLoading &&
        <Loader />
}
{
    /* wait until user permision have loaded */
    ( !userAudienceCountriesLoading ) &&
<>
    <Stepper sx={{mt: 1}} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
        {
            ( activeStep === 0 ) &&
            <>
            <Box sx={{pt: 3}}>
                {
                    audience.filters.map( (filter, filterIndex) => {
                        let searchType;
                        let contextLabel;

                        if( filter.context )
                        {
                            if( ['topArtists', 'topArtistsSimilar', 'artists', 'libraryArtists'].includes(filter.context) )
                            {
                                searchType = 'artist';
                                contextLabel = 'Artists';
                            }
                            else if( ['albums'].includes(filter.context) )
                            {
                                searchType = 'album';
                                contextLabel = 'Albums';
                            }
                            else if( ['tracks'].includes(filter.context) )
                            {
                                searchType = 'track';
                                contextLabel = 'Tracks';
                            }
                            else if( ['playlists'].includes(filter.context) )
                            {
                                searchType = 'playlist';
                                contextLabel = 'Playlists';
                            }
                        }

                        return (
                            <Accordion disabled={audienceDataLoading} elevation={0} sx={{backgroundColor: 'rgba(70, 190, 230, .1)'}} key={filterIndex} expanded={filter.expanded || false} onChange={handleExpansionChange(filterIndex)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{justifyContent: 'space-between'}}
                                >
                                    <Typography variant="h6" sx={{ width: '70%', flexShrink: 0 }}>
                                        
                                        {
                                            filter.context ?
                                                <>{contextOptions.find(contextSingle => contextSingle.value === filter.context).label}</>
                                            :
                                                <>Filter {filterIndex + 1}</>
                                        }
                                    </Typography>
                                    <Box sx={{width: '15%'}}>
                                        {
                                            filter.type && ( filter.type === 'include' ) &&
                                                <Chip color="success" label="Include" />
                                        }
                                        {
                                            filter.type && ( filter.type === 'exclude' ) &&
                                                <Chip color="error" label="Exclude" />
                                        }
                                    </Box>
                                    <Box sx={{width: '10%', textAlign: 'right'}}>
                                        {
                                            ( audience.filters.length > 1 ) &&
                                                <Button
                                                    size="small"
                                                    color="error"
                                                    variant="outlined"
                                                    endIcon={<RemoveCircleIcon />}
                                                    onClick={handleFilterDelete(filterIndex)}
                                                >
                                                    Remove
                                                </Button>
                                        }
                                    </Box>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Fieldset sx={{p: 0}}>
                                        <FieldContainer xs={12}>
                                            <InputLabel shrink>What do you Want to do? <Tooltip title={<>In order to make your search more specific, you can choose to include fans that match a particular criteria or exclude fans that match a certain criteria. For instance, you could look for fans that haven't listened to a particular track or have listened to a paricular track.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                            <Select
                                                onChange={handleFilterChange(filterIndex)}
                                                value={filter.type || ''}
                                                name="type"
                                                fullWidth
                                            >
                                                {
                                                    [
                                                        {value: 'include', label: 'Include Fans That Match The Following Criteria'},
                                                        {value: 'exclude', label: 'Exclude Fans That Match The Following Criteria'}
                                                    ].map( (item) => (
                                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FieldContainer>

                                        {
                                            filter.type &&
                                            <>
                                                <FieldContainer xs={12}>
                                                    <InputLabel shrink>Search For Fans That <Tooltip title={<>You can choose what type of interaction you would like to filter by.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                                    <Select
                                                        onChange={handleFilterChange(filterIndex)}
                                                        value={filter.context || ''}
                                                        name="context"
                                                        fullWidth
                                                    >
                                                        {
                                                            contextOptions.map( (item) => (
                                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                    {
                                                        ( filter.context && ['topArtists', 'topArtistsSimilar'].includes(filter.context) ) && <Typography sx={{mt: 1}} variant="body2">
                                                            { filter.context === 'topArtists' && <>Users that have had this artist or these artists in their top 50 listened to artists over the past 6 months.</> }
                                                            { filter.context === 'topArtistsSimilar' && <>Users that have had artists similar to this artist or these artists in their top 50 listened to artists over the past 6 months.</> }
                                                        </Typography>
                                                    }
                                                </FieldContainer>
                                                {
                                                    filter.context &&
                                                        <>
                                                            {
                                                                ( ['countries'].includes(filter.context) && countryData ) &&
                                                                    <FieldContainer xs={12}>
                                                                        <InputLabel shrink>Which Country Do You Want to Find Fans In? <Tooltip title={<>Leave this field blank to search all countries.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                        <CountrySelector
                                                                            name="countries"
                                                                            value={filter.value || []}
                                                                            placeholder="Type to search"
                                                                            options={userAudienceCountries ? countryData.filter(countryDataSingle => userAudienceCountries.includes(countryDataSingle.getA2())) : countryData}
                                                                            onChange={(event, newValue) => {
                                                                                handleFilterChangeSingle(filterIndex, 'value', newValue);
                                                                            }}
                                                                        />
                                                                    </FieldContainer>
                                                            }
                                                            {
                                                                ( ['topArtists', 'topArtistsSimilar', 'artists', 'albums', 'tracks', 'playlists', 'libraryArtists'].includes(filter.context) && countryData ) &&
                                                                    <FieldContainer xs={12}>
                                                                        <InputLabel shrink>
                                                                            {contextLabel}
                                                                        </InputLabel>

                                                                        <DSPSearch
                                                                            name="value"
                                                                            fullWidth
                                                                            placeholder="Type to search"
                                                                            service="spotify"
                                                                            type={searchType}
                                                                            value={filter.value || []}
                                                                            onChange={(event, newValue) => {
                                                                                handleFilterChangeSingle(filterIndex, 'value', newValue);
                                                                            }}
                                                                        />
                                                                    </FieldContainer>
                                                            }
                                                            {

                                                                ( filter.value && ( filter.value.length > 1 ) && ['topArtists', 'topArtistsSimilar', 'artists', 'albums', 'tracks', 'playlists', 'libraryArtists'].includes(filter.context) && countryData ) &&
                                                                    <FieldContainer xs={12}>
                                                                        <InputLabel shrink>Do You Want Fans That Listen to All or Any of these {contextLabel}? <Tooltip title={<>In order to make your search more specific, you can choose to include fans that match a particular criteria or exclude fans that match a certain criteria. For instance, you could look for fans that haven't listened to a particular track or have listened to a paricular track.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                            
                                                                        <Select
                                                                            onChange={handleFilterChange(filterIndex)}
                                                                            value={filter.preference || 'any'}
                                                                            name="preference"
                                                                            fullWidth
                                                                        >
                                                                            {
                                                                                [
                                                                                    {value: 'any', label: 'Fans That Listen to Any of These '+contextLabel},
                                                                                    {value: 'all', label: 'Fans That Listen to All of These '+contextLabel}
                                                                                ].map( (item) => (
                                                                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                                                ))
                                                                            }
                            
                                                                        </Select>
                                                                    </FieldContainer>
                                    
                                                            }
                                                            {
                                                                ( filter.value && ( filter.value.length > 0 ) && ['topArtists', 'topArtistsSimilar'].includes(filter.context) && countryData ) &&
                                                                <>
                                                                    <FieldContainer xs={12}>
                                                                        <InputLabel shrink>Favorite Threshold <Tooltip title={<>Fans favorite top artists are ranked 1 - 50, 1 being their favorite artist. These fields let you search for a cross-section of the audience's favorite top artists.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        
                                                                        <Slider min={0} max={50} onChange={handleFilterChange(filterIndex)} valueLabelDisplay="auto" value={filter.valueRange || [0, 50]} name="valueRange" />
                                                                    </FieldContainer>

                                                                </>
                                                            }
                                                            {
                                                                ( filter.value && ( filter.value.length > 0 ) && ['artists', 'albums', 'tracks', 'playlists'].includes(filter.context) && countryData ) &&
                                                                <>
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                        <FieldContainer xs={5.5}>                                        
                                                                            <InputLabel shrink>Streaming Dates <Tooltip title={<>The dates between which you'd like to search streams. Maximum date range is 28 days.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                            <DesktopDatePicker
                                                                                name="dateFrom"
                                                                                inputFormat="dd/MM/yyyy"
                                                                                value={filter.dateFrom || ''}
                                                                                onChange={(newValue) => {
                                                                                    if( filter.dateTo ){
                                                                                        const daysDifference = differenceInDays(filter.dateTo, newValue);

                                                                                        // If there's more than "dateRangeThreshold" days between these dates then change the dateFrom to be 28 days behind dateTo to avoid expensive queries
                                                                                        if( daysDifference > dateRangeThreshold ){
                                                                                            const updateDateTo = addDays(newValue, dateRangeThreshold);
                                                                                            handleFilterChangeSingle(filterIndex, 'dateTo', updateDateTo);
                                                                                        }
                                                                                    }

                                                                                    handleFilterChangeSingle(filterIndex, 'dateFrom', newValue);
                                                                                }}
                                                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                                                            />
                                                                        </FieldContainer>
                                                                        <FieldContainer xs={1} sx={{textAlign: 'center', mt: 5}}>
                                                                            <InputLabel>to</InputLabel>
                                                                        </FieldContainer>
                                                                        <FieldContainer xs={5.5}>                                        
                                                                            <InputLabel shrink>&nbsp;</InputLabel>
                                                                            <DesktopDatePicker
                                                                                name="dateTo"
                                                                                inputFormat="dd/MM/yyyy"
                                                                                value={filter.dateTo || ''}
                                                                                onChange={(newValue) => {
                                                                                    if( filter.dateFrom ){
                                                                                        const daysDifference = differenceInDays(newValue, filter.dateFrom);

                                                                                        // If there's more than "dateRangeThreshold" days between these dates then change the dateFrom to be 28 days behind dateTo to avoid expensive queries
                                                                                        if( daysDifference > dateRangeThreshold ){
                                                                                            const updateDateFrom = subDays(newValue, dateRangeThreshold);
                                                                                            handleFilterChangeSingle(filterIndex, 'dateFrom', updateDateFrom);
                                                                                        }
                                                                                    }

                                                                                    handleFilterChangeSingle(filterIndex, 'dateTo', newValue);
                                                                                }}
                                                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                                                            />
                                                                        </FieldContainer>
                                                                    </LocalizationProvider>

                                                                </>
                                                            }
                                                        </>
                                                }
                                            </>
                                        }
                                    </Fieldset>
                                </AccordionDetails>
                            </Accordion>
                        )
                        

                    })
                }
                </Box>

                <Box sx={{pt: 2, textAlign: 'right'}}>
                <Button
                        color="info"
                        variant="outlined"
                        endIcon={<AddBoxIcon />}
                        onClick={handleFilterAdd()}
                        sx={{ml: 1}}
                        disabled={audienceDataLoading}
                    >
                        Add Filter
                    </Button>

                    <Button
                        color="info"
                        variant="outlined"
                        endIcon={<SendIcon />}
                        onClick={(e) => {
                            let encodedAudience = JSON.stringify(audience);

                            let link = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+window.location.pathname;

                            navigator.clipboard.writeText(link+'?audience='+btoa(encodedAudience));
                            
                            enqueueSnackbar("Audience link copied to clipboard", {
                              variant: 'success'
                            });
                        }}
                        sx={{ml: 1}}
                    >
                        Share Audience
                    </Button>

                    <LoadingButton
                        loading={audienceDataLoading}
                        disabled={!(audience.filters && audience.filters[0] && audience.filters[0].context)}
                        color="info"
                        variant="contained"
                        endIcon={<PersonSearchIcon />}
                        onClick={handleFilterSearch()}
                        sx={{ml: 1}}
                        loadingPosition="end"
                    >
                        Build Audience
                    </LoadingButton>
                </Box>
                
                {
                    ( !audienceDataLoading && (audienceDataTotal !== null) ) &&
                    <Box sx={{pt: 3}}>
                        <Alert severity="success">
                            Your selected audience contains {audienceDataTotal.toLocaleString()} fans.
                        </Alert>
                        
                        <Box sx={{pt: 2, textAlign: 'right'}}>

                            <Button
                                color="info"
                                variant="outlined"
                                endIcon={<KeyboardArrowDownIcon  />}
                                onClick={toggleExportMenu}
                                disabled={audienceDataLoading}
                            >
                                export
                            </Button>
                            <Menu
                                anchorEl={anchorExportMenu}
                                open={openExportMenu}
                                onClose={toggleExportMenu}
                                onClick={toggleExportMenu}
                            >
                                {
                                    [
                                        {
                                            label: "CSV",
                                            value: "csv"
                                        },
                                        {
                                            label: "Facebook",
                                            value: "facebook"
                                        }
                                    ]
                                    .map((exportType) => (
                                        <MenuItem onClick={handleExport(exportType.value)} key={exportType.value}>
                                        {exportType.label}
                                        </MenuItem>
                                    ))
                                }
                            </Menu>
                        </Box>
                    </Box>
                }
            </>
        }
        {
            ( activeStep === 1 ) &&
                <>
            <Box sx={{pt: 3}}>

                    <Fieldset>
                        <FieldContainer xs={12}>
                            <Typography variant="h6">Basic Settings</Typography>
                        </FieldContainer>

                        <FieldContainer xs={12}>
                            <InputLabel shrink>Email Type</InputLabel>

                            <Select
                                onChange={handleEmailFormDataChange}
                                value={emailFormData.type || ''}
                                name="type"
                                fullWidth
                            >
                                {
                                    emailTemplateData &&
                                        emailTemplateData.getItems().map( (item) => (
                                            <MenuItem key={item.getId()} value={item.getId()}>{item.getName()} ({item.getDescription()})</MenuItem>
                                        ))
                                }

                            </Select>
                        </FieldContainer>

                        {
                            emailFormData.type &&
                            <>
                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Language For This Send <Tooltip title={<>Choose a language for this send to automatically load the pre-sets for your chosen language or alternatively choose "Other" to enter custom text.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                    <Select
                                        onChange={handleEmailFormDataChange}
                                        value={emailFormData.language || ''}
                                        name="language"
                                        fullWidth
                                    >
                                        {
                                            emailTemplateData.getItems().find(item => item.getId() === emailFormData.type).getTranslations().map( (item) => (
                                                <MenuItem key={item.getId()} value={item.getLanguage().getId()}>{item.getLanguage().getName()}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FieldContainer>
                            
                            </>
                        }
                        {
                            ( emailFormData.language && ['Follow', 'Pre Save', 'New Music'].includes(emailTemplateData.getItems().find(item => item.getId() === emailFormData.type).getName()) ) &&
                                <FieldContainer xs={12}>
                                    <InputLabel shrink>
                                        Search For An Artist
                                    </InputLabel>

                                    <DSPSearch
                                        name="artist"
                                        fullWidth
                                        placeholder="Type to search"
                                        service="spotify"
                                        type="artist"
                                        value={emailFormData.artist || []}
                                        onChange={(event, newValue) => {
                                            let newValueSingle = newValue[0] || null
                                            if( newValueSingle )
                                            {
                                                if( newValueSingle.image )
                                                {
                                                    setEmailFormDataSingle('image', newValueSingle.image);
                                                }
    
                                                setEmailFormDataSingle('artist', newValueSingle);
                                            }
                                        }}
                                    />
                                </FieldContainer>
                        }
                        {
                            ( emailFormData.language && ['Playlist'].includes(emailTemplateData.getItems().find(item => item.getId() === emailFormData.type).getName()) ) &&
                                <FieldContainer xs={12}>
                                    <InputLabel shrink>
                                        Search For A Playlist
                                    </InputLabel>

                                    <DSPSearch
                                        name="playlist"
                                        fullWidth
                                        placeholder="Type to search"
                                        service="spotify"
                                        type="playlist"
                                        value={emailFormData.playlist || []}
                                        onChange={(event, newValue) => {
                                            let newValueSingle = newValue[0] || null
                                            if( newValueSingle )
                                            {
                                                if( newValueSingle.image )
                                                {
                                                    setEmailFormDataSingle('image', newValueSingle.image);
                                                }
    
                                                setEmailFormDataSingle('playlist', newValueSingle);
                                            }
                                        }}
                                    />
                                </FieldContainer>
                        }
                        {
                            ( emailFormData.language && ['One Click Pre Save'].includes(emailTemplateData.getItems().find(item => item.getId() === emailFormData.type).getName()) ) &&
                                <FieldContainer xs={12}>
                                    <InputLabel shrink>
                                        Search For A Campaign
                                    </InputLabel>

                                    <Search
                                        name="campaign"
                                        fullWidth
                                        placeholder="Type to search"
                                        service="acquisition"
                                        type="campaign"
                                        value={emailFormData.campaign || []}
                                        onChange={(event, newValue) => {
                                            let newValueSingle = newValue[0] || null
                                            if( newValueSingle )
                                            {
                                                if( newValueSingle.image )
                                                {
                                                    setEmailFormDataSingle('image', newValueSingle.image);
                                                }
    
                                                setEmailFormDataSingle('campaign', newValueSingle);
                                            }
                                        }}
                                    />
                                </FieldContainer>
                        }

                        {
                            ( emailFormData.artist || emailFormData.playlist || emailFormData.campaign ||( emailFormData.language && emailFormData.type && ['Custom'].includes(emailTemplateData.getItems().find(item => item.getId() === emailFormData.type).getName()) ) ) &&
                            <>
                                <FieldContainer xs={12}>
                                    <Divider />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <Typography variant="h6">Subject & Pre-Header</Typography>
                                </FieldContainer>

                                <FieldContainer xs={6}>
                                    <InputLabel shrink>Email Subject <Tooltip title={<>This field should be between 40 and 60 characters but no longer than 80.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        name="subject"
                                        fullWidth
                                        value={emailFormData.subject || ''}
                                        onChange={handleEmailFormDataChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={6}>
                                    <InputLabel shrink>Email Pre-Header <Tooltip title={<>This field should be between 40 and 60 characters but no longer than 80.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        name="preHeader"
                                        fullWidth
                                        value={emailFormData.preHeader || ''}
                                        onChange={handleEmailFormDataChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <Divider />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <Typography variant="h6">Content</Typography>
                                </FieldContainer>

                                <FieldContainer xs={6}>
                                    <InputLabel shrink>Email Title <Tooltip title={<>This text will appear at the top of the email. It should be between 40 and 60 characters but no longer than 80.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        name="title"
                                        fullWidth
                                        value={emailFormData.title || ''}
                                        onChange={handleEmailFormDataChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={6}>
                                    <InputLabel shrink>Email Header <Tooltip title={<>This text will appear at the top of the email, just below the 'Email Title'.  It should be between 40 and 60 characters but no longer than 80.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        name="header"
                                        fullWidth
                                        value={emailFormData.header || ''}
                                        onChange={handleEmailFormDataChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Content <Tooltip title={<>An optional block of text that will appear towards the bottom of the email. Should be no longer than 120 characters.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        value={emailFormData.content || ''}
                                        name="content"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        onChange={handleEmailFormDataChange}
                                    />
                                </FieldContainer>
                                <FieldContainer xs={6}>
                                    <InputLabel shrink>Image <Tooltip title={<>Image of the album or single artwork. If no image is given, then the artist's Spotify profile image is used. This file must not exceed 1MB & should be no larger than 640 pixels wide and 640 pixels high.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <ImageUpload
                                        value={emailFormData.image || ''}
                                        onChange={(value) => {
                                            setEmailFormDataSingle('image', value);
                                        }}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <Divider />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <Typography variant="h6">Call to Action</Typography>
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Email Button Link <Tooltip title={<>This is the link for the email's main call to action.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        name="buttonLink"
                                        fullWidth
                                        value={emailFormData.buttonLink || ''}
                                        onChange={handleEmailFormDataChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Email Button Text <Tooltip title={<>This is the text that will appear on the link for the email's main call to action.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        name="buttonText"
                                        fullWidth
                                        value={emailFormData.buttonText || ''}
                                        onChange={handleEmailFormDataChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <Divider />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <Typography variant="h6">Send Test Email</Typography>
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Email Address <Tooltip title={<>Enter the email that's associated with the fan's Spotify account</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                    <EntrantSearch
                                        name="testEmail"
                                        source="spotify"
                                        fullWidth
                                        multiple
                                        field="email"
                                        value={emailFormData.testEmail || ''}
                                        placeholder="Add an email"
                                        onChange={(event, newValue) => {
                                            setEmailFormDataSingle('testEmail', newValue);
                                        }}
                                    />

                                    <Box sx={{pt: 2, textAlign: 'right'}}>
                                        <LoadingButton
                                            loading={emailTestLoading}
                                            color="info"
                                            variant="contained"
                                            endIcon={<SendIcon />}
                                            onClick={handleTestEmailSend()}
                                            sx={{ml: 1}}
                                            loadingPosition="end"
                                            disabled={!emailFormData.testEmail}
                                        >
                                            Send Test Email
                                        </LoadingButton>
                                    </Box>
                                </FieldContainer>
                                

                            </>
                        }

                    </Fieldset>
                    </Box>
                </>
        }
        {
            ( activeStep === 2 ) &&
                <>
                    <Box sx={{pt: 3}}>

                        <Fieldset>
                            <FieldContainer xs={12}>
                                <Typography variant="h6">Scheduled Date & Time</Typography>

                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        name="sendDateTime"
                                        value={emailFormData.sendDateTime || ''}
                                        onChange={(newValue) => {
                                            setEmailFormDataSingle('sendDateTime', newValue);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </FieldContainer>
{/*
                            <FieldContainer xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <InputLabel shrink>Date</InputLabel>
                                    <DesktopDatePicker
                                        name="date"
                                        inputFormat="dd/MM/yyyy"
                                        value={emailFormData.date || ''}
                                        onChange={(newValue) => {
                                            setEmailFormDataSingle('date', newValue);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />

                                </LocalizationProvider>
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <InputLabel shrink>Time</InputLabel>
                                    <TimePicker
                                        name="time"
                                        value={emailFormData.time || ''}
                                        onChange={(newValue) => {
                                            setEmailFormDataSingle('time', newValue);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </FieldContainer>

                                    */}

                        </Fieldset>

                    </Box>
                </>
        }
        {
            ( activeStep === 3 ) &&
                <>
                    <Box sx={{pt: 3}}>
                        
                        <Fieldset>
                            <FieldContainer xs={12}>
                                <Typography variant="h6" gutterBottom>Confirm Send</Typography>
                                    <Alert severity="info">
                                        Click below to schedule a send to <strong>{audienceDataTotal.toLocaleString()}</strong> fans on <strong>{emailFormData.sendDateTime.toLocaleString().split(',')[0]}</strong> at <strong>{emailFormData.sendDateTime.toLocaleString().split(',')[1]}</strong>.
                                    </Alert>
                            </FieldContainer>
                        </Fieldset>

                    </Box>
                </>
        }
        {
            ( activeStep === 4 ) &&
                <>
                    <Box sx={{pt: 3}}>

                        <Fieldset>
                            <FieldContainer xs={12}>
                                <Typography variant="h6" gutterBottom>Confirm Send</Typography>
                                    <Alert severity="success">
                                        <MLink component={Link} to={`/insights/${audienceSchedule.getId()}/stats`}>Your send has been scheduled.</MLink> Click to view more details about your send or click below to create another audience.
                                    </Alert>
                            </FieldContainer>
                        </Fieldset>

                    </Box>
                </>
        }
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="text"
                    disabled={activeStep === 0 || ( audienceScheduleSubmitting || audienceSchedule )}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>

                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                    <Button variant="text" onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                    </Button>
                )}

                {
                    ( activeStep === steps.length - 1 ) ?
                        <LoadingButton
                            loading={audienceScheduleSubmitting}
                            variant="text"
                            onClick={handleNext}
                        >
                            Confirm & Finish
                        </LoadingButton>
                    :
                        <Button
                            variant="text"
                            onClick={handleNext}
                            disabled={!isStepCompleted(activeStep) && !audienceSchedule}
                        >
                            { audienceSchedule ? 'Create Another Audience' : 'Next' }
                        </Button>
                }

            </Box>
            </>
}
      </ContentBox>
            </PageContainer>
        </>
    );
};