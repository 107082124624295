import React, { Fragment, useEffect, useState } from 'react';

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TablePagination, Chip, Box, Button, ButtonGroup, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton, OutlinedInput, Alert, TableRow, TableCell, TableHead, TableBody, Table, TableContainer, Switch } from '@mui/material';

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import ContextMenu from '../../ContextMenu';

import { CDCAPI } from '@warnerconnect/library';

import RadioRangeBackgroundImage from '../../../../assets/img/radioRange.background.png';

import {isObject} from '../../../Utilities/Utilities';
import Flag from 'react-world-flags'
import FieldContainer from '../FieldContainer';
import RichTextEditor from './RichTextEditor';
import ModalDialogue from '../../ModalDialogue';
import { typographyVariant } from '@mui/system';
import Fieldset from '../Fieldset';

export default function OptIn(props) {
    for(let i in props.value){
        if( !isObject(props.value[i]) ){
            props.value[i] = {};
        }
    }

    const [override, setOverride] = useState(props.override || false);
    const [listData, setListData] = useState(props.value || [{}]);
    const [selectedModal, setModal] = useState(false);
    const [listSearchData, setListSearchData] = useState(null);
    const [listSearchText, setListSearchText] = useState(null);
    const [listSearchLoading, setListSearchLoading] = useState(false);
    const [listSearchHandler, setListSearchHandler] = useState(null);
    const [advanced, toggleAdvanced] = useState(false);

    const cdcAPI = new CDCAPI();

    useEffect(() => {
        if( listSearchHandler )
        {
            listSearchHandler.abort();
        }

        if( listSearchText && ( listSearchText.length >= 3 ) )
        {
            setListSearchLoading(true);

            var searchAbortController = new AbortController();

            setListSearchHandler(searchAbortController);

            cdcAPI
                .checkListByName(listSearchText, {signal: searchAbortController.signal})
                .then( (response) => {
                    setListSearchData(response.lists);
                    setListSearchLoading(false);
                })
                .catch( (e) => {} )
                .finally( () => {
                });
        }
    }, [listSearchText]);

    useEffect(() => {
        setOverride(props.override);
    }, [props.override]);

    const addSMS = () => (e) => {
        handleOptInAdd(0, 'community')(e);
    }

    const handleOptInDelete = (optInItemIndex) => (e) => {
        var listDataNew = [...listData];

        listDataNew.splice(optInItemIndex, 1);

        setListData(listDataNew);
    };

    const handleOptInAdd = (optInItemIndex, type = 'default') => (e) => {
        var listDataNew = [...listData];

        listDataNew.splice(optInItemIndex + 1, 0, {
            data_extension: {},
            id: [],
            label: "",
            mandatory: 0,
            trigger: {},
            type: type
        });

        setListData(listDataNew);
    };

    const handleListDelete = (optInItemIndex, listIdIndex) => (e) => {
        var listDataNew = [...listData];

        listDataNew[optInItemIndex].id.splice(listIdIndex, 1);
        listDataNew[optInItemIndex].entity.splice(listIdIndex, 1);

        setListData(listDataNew);
    };

    const handleListAdd = (optInItemIndex) => (e) => {
        var listDataNew = [...listData];
        listDataNew[optInItemIndex].id.push('');

        setListData(listDataNew);
    };

    const handleListChange = (optInItemIndex, listIdIndex, value = null, closeModal = false) => (e) => {
        var listDataNew = [...listData];
        let correctValue = value || e.target.value;

        if( !listDataNew[optInItemIndex].id )
        {
            listDataNew[optInItemIndex].id = [];
        }
        
        if( !listDataNew[optInItemIndex].entity )
        {
            listDataNew[optInItemIndex].entity = [];
        }

        listDataNew[optInItemIndex].id[listIdIndex] = correctValue;
        listDataNew[optInItemIndex].entity[listIdIndex] = parseInt(correctValue) === 14077858 ? 'Warner Music Group companies' : ( listDataNew[optInItemIndex].entity[listIdIndex] || '' );

        if( closeModal )
        {
            closeListSearch();
        }
        
        setListData(listDataNew);
    };

    const handleEntityChange = (optInItemIndex, listIdIndex) => (e) => {
        var listDataNew = [...listData];

        console.log(optInItemIndex, listIdIndex);

        listDataNew[optInItemIndex].entity[listIdIndex] = e.target.value;

        setListData(listDataNew);
    };

    const handleMandatoryChange = (optInItemIndex) => (e) => {
        var listDataNew = [...listData];

        listDataNew[optInItemIndex].mandatory = e.target.checked ? 1 : 0;

        setListData(listDataNew);
    };

    const handleTriggerChange = (optInItemIndex, listIdIndex) => (e) => {
        var listDataNew = [...listData];

        if( Array.isArray(listDataNew[optInItemIndex].trigger) ){
            listDataNew[optInItemIndex].trigger = {};
        }
        listDataNew[optInItemIndex].trigger[listIdIndex] = e.target.value;

        setListData(listDataNew);
    };

    const handleDataExtensionChange = (optInItemIndex, listIdIndex) => (e) => {
        var listDataNew = [...listData];

        if( Array.isArray(listDataNew[optInItemIndex].data_extension) ){
            listDataNew[optInItemIndex].data_extension = {};
        }

        listDataNew[optInItemIndex].data_extension[listIdIndex] = e.target.value;

        setListData(listDataNew);
    };

    const handleLabelChange = (optInItemIndex, value) => {
        var listDataNew = [...listData];

        listDataNew[optInItemIndex].label = value;

        setListData(listDataNew);
    };

    const openListSearch = (optInItemIndex, listDataSingleIndex) => (e) => {
        setModal({optInItemIndex: optInItemIndex, listDataSingleIndex: listDataSingleIndex});
    };

    const closeListSearch = (e) => {
        setModal(false);
        setListSearchData(null);
        setListSearchLoading(false);
        setListSearchText(null)
    };

    const handleListSearch = (e) => {
        setListSearchText(e.target.value);
    };

    useEffect(() => {
        if( props.onChange )
        {
            props.onChange(listData);
        }
    }, [listData]);

    const containsCommunity = listData.find(listDataSingle => listDataSingle.type === 'community');
    
    return (
        <>

            <ModalDialogue open={selectedModal === false ? false : true} onClose={closeListSearch}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Search Mailing Lists</Typography>
                    <Typography variant="body1">When users enter this campaign their email can be send to ExactTarget so that we can communicate with them in the future. You can search for Newsletter IDs below so that user emails go to the correct list.</Typography>
                </Box>
                <Fieldset>                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="">List Name</InputLabel>
                        <TextField
                            fullWidth
                            onKeyUp={handleListSearch}
                        />
                        {
                            listSearchLoading &&
                                <Alert
                                    icon={<CircularProgress size={20} />}
                                    severity="info"
                                    sx={{mt: 2}}
                                >
                                    Searching
                                </Alert>
                        }
                        {
                            ( !listSearchLoading && listSearchData && ( listSearchData.length > 0 ) ) &&
                                <TableContainer sx={{mt: 2}} component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Newsletter ID</TableCell>
                                                <TableCell>Main List</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {
                                            listSearchData.map( listData => (
                                                    <TableRow hover key={listData.newsletterId}>
                                                        <TableCell>{listData.name}</TableCell>
                                                        <TableCell>{listData.newsletterId}</TableCell>
                                                        <TableCell>{listData['main list'] === 'Y' ? 'Yes' : 'No'}</TableCell>
                                                        <TableCell>
                                                            <Button
                                                                onClick={handleListChange(selectedModal.optInItemIndex, selectedModal.listDataSingleIndex, listData.newsletterId, true)}
                                                            >
                                                                Select
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                            ))
                                        }
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                        }
                        {
                            ( !listSearchLoading && listSearchData && ( listSearchData.length === 0 ) ) &&
                                <Alert
                                    severity="warning"
                                    sx={{mt: 2}}
                                >
                                    No results returned
                                </Alert>
                        }
                    </FieldContainer>
                </Fieldset>
            </ModalDialogue>

            <Button variant="outlined" size="medium" onClick={() => {toggleAdvanced(!advanced)}}>{ advanced ? 'Hide Advanced Options' : 'Show Advanced Options' }</Button>

            {
                (!containsCommunity) &&
                    <Button variant="outlined" size="medium" sx={{ml: 2}} onClick={addSMS()}>Add SMS</Button>
            }

            {
                listData.map( (listDataSingle, listDataKey) => {
                    const listItemType = listDataSingle.type ? listDataSingle.type : 'default';
                    let idLabel;

                    if(listItemType === 'community'){
                        idLabel = 'Community Lead ID';
                    }else{
                        idLabel = 'List ID';
                    }

                    return (
                        <FieldContainer xs={12} key={listDataKey} sx={{pt: 4, pb: 4, backgroundImage: `url(${RadioRangeBackgroundImage})`, backgroundPosition: 'left bottom', backgroundRepeat: 'repeat-x'}}>
                            {
                                ( !listDataSingle.mandatory ) &&
                                    ( ( listDataSingle.id && ( listDataSingle.id.length > 0 ) ) ? listDataSingle.id : ['']).map( (listDataSingleKey, listDataSingleIndex) => (
                                        <Fragment key={listDataSingleIndex}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <FormControl sx={{ mb: 2, pr: 1 }} variant="outlined" fullWidth>
                                                        <InputLabel size="small" htmlFor={`${listDataKey}-list`}>{idLabel}</InputLabel>

                                                        <OutlinedInput
                                                            size="small"
                                                            value={listDataSingleKey}
                                                            id={`${listDataKey}-list`}
                                                            onChange={handleListChange(listDataKey, listDataSingleIndex)}
                                                            endAdornment={
                                                                <>
                                                                    {
                                                                        ( listItemType === 'default' ) && <>
                                                                            <InputAdornment position="end">
                                                                                <IconButton onClick={openListSearch(listDataKey, listDataSingleIndex)} edge="end">
                                                                                    <SearchIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </InputAdornment>

                                                                            <InputAdornment position="end">
                                                                                <IconButton onClick={handleListAdd(listDataKey)} edge="end">
                                                                                    <AddIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </InputAdornment>

                                                                            { ( listDataSingleKey || ( listDataSingle.id && ( listDataSingle.id.length > 1 ) ) ) &&
                                                                                <InputAdornment position="end">
                                                                                    <IconButton onClick={handleListDelete(listDataKey, listDataSingleIndex)} edge="end">
                                                                                        <DeleteIcon fontSize="small" />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        </>
                                                                    }

                                                                </>
                                                            }
                                                            label="List ID"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} sx={{pl: 1}}>
                                                    <TextField
                                                        label="Legal Opt-In Entity"
                                                        disabled={override ? false : ( parseInt(listDataSingleKey) === 14077858 )}
                                                        size="small"
                                                        error={!listDataSingle.entity || !listDataSingle.entity[listDataSingleIndex]}
                                                        helperText={!listDataSingle.entity || !listDataSingle.entity[listDataSingleIndex] ? 'Please enter then name of the legal entity the user is opting into, for example "Ed Sheeran" or "Atlantic Records"' : ''}
                                                        onChange={handleEntityChange(listDataKey, listDataSingleIndex)}
                                                        sx={{mb: 2}}
                                                        value={listDataSingle.entity && listDataSingle.entity[listDataSingleIndex] ? listDataSingle.entity[listDataSingleIndex] : ''}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                            {
                                                advanced &&
                                                    <>
                                                        <TextField
                                                            label="Trigger"
                                                            size="small"
                                                            value={ listDataSingle['trigger'] && listDataSingle['trigger'][listDataSingleKey] ? listDataSingle['trigger'][listDataSingleKey] : '' }
                                                            onChange={handleTriggerChange(listDataKey, listDataSingleKey)}
                                                            fullWidth
                                                            sx={{mb: 2}}
                                                        />

                                                        <TextField
                                                            label="Data Extension"
                                                            size="small"
                                                            value={ listDataSingle['data_extension'] && listDataSingle['data_extension'][listDataSingleKey] ? listDataSingle['data_extension'][listDataSingleKey] : '' }
                                                            onChange={handleDataExtensionChange(listDataKey, listDataSingleKey)}
                                                            fullWidth
                                                            sx={{mb: 2}}
                                                        />

                                                        <Divider sx={{mb: 2}} />
                                                    </>
                                                
                                            }
                                        </Fragment>
                                    ))
                            }

                            {
                                ( props.simple !== true ) &&
                                    <RichTextEditor
                                        size="auto"
                                        disabled={override || listItemType !== 'default' ? false : ( listDataSingle.mandatory ? false : true )}
                                        value={
                                            listDataSingle.label ? 
                                                (
                                                    (override || listItemType !== 'default') ?
                                                        listDataSingle.label
                                                            :
                                                        listDataSingle.label.replace('LEGAL_ENTITIES', listDataSingle.entity && listDataSingle.entity.filter(n => n).length > 0 ? [...listDataSingle.entity].filter(n => n).reverse().join(', ').replace(/, ([^,]*)$/, ' and $1') : 'Warner Music Group companies' )
                                                )
                                                :
                                                ''
                                        }
                                        onEditorChange={(value) => {
                                            if( listDataSingle.mandatory || (override || listItemType !== 'default') ){
                                                handleLabelChange(listDataKey, value)
                                            }
                                        }}
                                    />
                            }

                            {
                                (override || listItemType !== 'default') &&
                                <>
                                    {
                                        ( listData.filter(element => !element.mandatory).length > 1) &&
                                            <Button onClick={handleOptInDelete(listDataKey)} sx={{mt: 1, mr: 1}} variant="secondary" size="medium" startIcon={<DeleteIcon />}>Remove This Opt-In</Button>
                                    }

                                    {
                                        ( props.simple !== true ) &&
                                            <Button onClick={handleOptInAdd(listDataKey)} sx={{mt: 1, mr: 1}} variant="secondary" size="medium" startIcon={<AddIcon />}>{ props.simple !== true ? 'Add Opt-In Below' : 'Add List Below' }</Button>
                                    }

                                    <FormControlLabel sx={{mt: 1, mr: 1}} control={<Switch onChange={handleMandatoryChange(listDataKey)} checked={listDataSingle.mandatory} />} label={"Is Checkbox Mandatory?"} />
                                </>
                            }

                        </FieldContainer>
                    );
                })
            }
        </>
    );
}