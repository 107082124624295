import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Grid, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Checkbox, Box } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import Loader from '../../Elements/Loader';
import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import ContentBox from '../../Elements/ContentBox';
import PageContainer from './../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import TableBarChart from '../../Elements/TableBarChart';
import BarChart from '../../Elements/Chart/BarChart';
import LineChart from '../../Elements/Chart/LineChart';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';

export default function ResourcesIndex () {
    useTitle('Resources');

    return (
        <>
            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Getting Started Guide</Typography>
                    <Typography paragraph>This guide gives a brief overview of Warner Connect's Campaigns platform showing it's various features & benefits along with instructions on how you can create your own campaigns.</Typography>
                </HeroText>
            </Hero>
            <PageContainer>
                <Typography variant="h4">Onboarding Video</Typography>

                <Box sx={{mt: 2}}>
                    <video controls width="100%" height="auto" src="https://cdn.campaigns.wmgconnect.com/media/WarnerConnectOnboarding.mp4" />
                </Box>

            </PageContainer>
        </>
    );

};
